import Layout from "./components/Layout/Layout.js";
import Wpage from "./components/Wpage/Wpage.js";
import Portfolio from "./components/Portfolio/Portfolio.js";
import Contact from "./components/Contact/Contact.js";
import Error from "./components/Error/Error.js";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Wpage />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
