export default function Wpage() {
    return (
        <main>
            <div className="relative isolate px-6 pt-14 lg:px-8">
            <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
            <div className="text-center">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"> Ancora Digital</h1>
                <br />
                <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Desbloqueamos el crecimiento de tu negocio.
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                En Ancora Digital somos <b>expertos</b> en transformación digital. Contamos con experiencia en <b>transformación de procesos</b> de desarrollo de software, también en la implementación de <b>metodologías ágiles</b> y en la <b>adopción de tecnologías emergentes</b>. Nuestro equipo de expertos te <b>ayudaremos a potenciar y desbloquear tu transformación digital.</b>
                </p>
            </div>
            </div>
        </div>
      </main>
    );
}